export const ShopLinks = [
  {
    id: 1,
    path: "/shop/raw-dog-food",
    text: "Raw Dog Food",
  },
  {
    id: 2,
    path: "/shop/cooked-dog-food",
    text: "Cooked Dog Food",
  },
  {
    id: 3,
    path: "/shop/dry-dog-food",
    text: "Dry Food",
  },
  {
    id: 4,
    path: "/shop/dog-treats",
    text: "Treats",
  },
];

/* eslint-disable react/no-danger */
import { useEffect, useState } from "react";

/* eslint-disable no-restricted-imports */
import { ApolloProvider } from "@apollo/client";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Elements } from "@stripe/react-stripe-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Analytics } from "@vercel/analytics/react";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import Error from "next/error";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";

import "~/styles/styles.css";
import "react-day-picker/dist/style.css";
import "@livechat/design-system-react-components/dist/style.css";
import apolloClient from "@/libv2/apolloClient";
import { initialPaypalOptions } from "~/components/commerce/paypal";
import {
  CartProvider,
  GlobalStateProvider,
  OrderProvider,
  SearchProvider,
  ShopFilterProvider,
  AuthProvider,
} from "~/context";
import { getStripe } from "~/lib/Stripe";
import { ClearModalOnNavigate, LiveChat, Hotjar } from "~/utilities";

import SEO from "../../next-seo.config";
import FullScreenLoader from "~/ui/FullScreenLoader/FullScreenLoader";
import { OrderConfirmContextProvider } from "~/context/OrderConfirmContext";
import { CategoryContextProvider } from "~/context/CategoryContext";
import { ProductContextProvider } from "~/context/ProductContext";
import { GoogleAnalytics } from "@next/third-parties/google";
import {
  LocalFont,
  MadelynRegular1,
  MadelynRegular2,
  TuckerTubFont1,
  TuckerTubFont2,
} from "~/ui/fonts";
import { ThemeProvider } from "@livechat/design-system-react-components";
//import { HomeContextProvider } from '~/context/HomeContext';
//import { fetchWithBasicAuth } from '~/context/fetchWithBasicAuth';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [isFullScreenLoader, setFullScreenLoader] = useState(false);

  const toggleFullScreenModal = (value: boolean) => {
    setFullScreenLoader(value);
  };

  useEffect(() => {
    const updateCanonicalLink = () => {
      const canonicalElement = document.getElementById(
        "seo-canonical"
      ) as HTMLLinkElement;
      if (canonicalElement) {
        canonicalElement.href =
          window.location.origin + window.location.pathname;
      }
    };

    // Update the canonical link on initial load
    updateCanonicalLink();

    // Update the canonical link on route change
    router.events.on("routeChangeComplete", updateCanonicalLink);

    // Cleanup the event listener on unmount
    return () => {
      router.events.off("routeChangeComplete", updateCanonicalLink);
    };
  }, [router.events]);

  useEffect(async () => {
    try {
      router.events.on("routeChangeComplete", () => {
        // fbq('track', 'PageView');
      });
    } catch (error) {
      //console.log('track pageView via GTM');
    }
    // initializeAnalytics();
    // const slugResponse = await fetchWithBasicAuth(
    //   `https://staging-tuckertub-staging.kinsta.cloud/wp-json/wc/v3/products/categories?slug=coocked-food`,
    //   'ck_6cd942c259912ce3bd75dca422a8f54dc05b1659',
    //   'cs_edba63bd065bc8b9ea5e9e84e5126a27bdb71367'
    // );
  }, []);

  // useEffect(() => {
  //   router.events.on("routeChangeError", (e) => setFullScreenLoader(false));
  //   router.events.on("routeChangeStart", (e) => setFullScreenLoader(true));
  //   router.events.on("routeChangeComplete", (e) => setFullScreenLoader(false));

  //   return () => {
  //     router.events.off("routeChangeError", (e) => setFullScreenLoader(false));
  //     router.events.off("routeChangeStart", (e) => setFullScreenLoader(false));
  //     router.events.off("routeChangeComplete", (e) => setFullScreenLoader(true));
  //   };
  // }, [router.events]);

  if (pageProps.error) {
    return (
      <Error
        statusCode={pageProps.error.statusCode}
        title={pageProps.error.message}
      />
    );
  }

  // Create a client
  const queryClient = new QueryClient();

  // creating canonical URL variable
  // const canonicalUrl = `https://www.tuckertub.com.au${router.asPath.split("?")[0]}`;

  const stripePromise = getStripe();

  let canonicalUrl = `https://www.tuckertub.com.au${
    router.asPath == "/" ? "" : router.asPath.split("?")[0]
  }`;
  return (
    <>
      {isFullScreenLoader && <FullScreenLoader />}
      <ThemeProvider>
        <ApolloProvider client={apolloClient}>
          <QueryClientProvider client={queryClient}>
            <GlobalStateProvider
              isFullScreenLoader={isFullScreenLoader}
              toggleFullScreenModal={(value) => toggleFullScreenModal(value)}
            >
              <AuthProvider>
                <SearchProvider>
                  <OrderProvider>
                    <CartProvider isFullScreenLoader={isFullScreenLoader}>
                      <ShopFilterProvider>
                        <ClearModalOnNavigate />
                        <LiveChat />
                        <Hotjar />
                        <DefaultSeo {...SEO} />
                        <PayPalScriptProvider
                          deferLoading={false}
                          options={initialPaypalOptions}
                        >
                          <OrderConfirmContextProvider>
                            <Elements
                              stripe={stripePromise}
                              options={{
                                appearance: {
                                  theme: "stripe",
                                },
                                fonts: [
                                  {
                                    family: "haboro-soft",
                                    cssSrc:
                                      "https://use.typekit.net/umo3ziv.css",
                                    display: "swap",
                                  },
                                ],
                              }}
                            >
                              <CategoryContextProvider>
                                <ProductContextProvider>
                                  {/* <HomeContextProvider> */}
                                  <Head>
                                    <meta
                                      name="viewport"
                                      content="width=device-width, initial-scale=1.0"
                                    />
                                    {router.pathname !=
                                      "/shop/[categoryHandle]/[productHandle]" && (
                                      <link
                                        rel="canonical"
                                        href={canonicalUrl}
                                      />
                                    )}
                                    {/* <link rel="canonical" href={canonicalUrl} /> */}
                                  </Head>
                                  <div
                                    className={`${LocalFont.variable} ${
                                      MadelynRegular1.variable
                                    } ${MadelynRegular2.variable} ${
                                      TuckerTubFont1.variable
                                    } ${TuckerTubFont2.variable} ${
                                      isFullScreenLoader &&
                                      "loader-full-page-height"
                                    } `}
                                  >
                                    <Component {...pageProps} />
                                  </div>
                                  {/* </HomeContextProvider> */}
                                </ProductContextProvider>

                                <GoogleAnalytics
                                  gaId={
                                    process.env
                                      .NEXT_PUBLIC_GOOGLE_GLOBAL_SITE_TAG4 || ""
                                  }
                                />
                              </CategoryContextProvider>
                              <Analytics />
                            </Elements>
                          </OrderConfirmContextProvider>
                        </PayPalScriptProvider>
                      </ShopFilterProvider>
                    </CartProvider>
                  </OrderProvider>
                </SearchProvider>
              </AuthProvider>
            </GlobalStateProvider>
          </QueryClientProvider>
        </ApolloProvider>
      </ThemeProvider>
      <Script
        id="gtm_tag"
        //defer
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          // __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          // new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          // j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          // 'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${process.env.GOOGLE_TAG_MANAGER_AUTH}&gtm_preview=${process.env.GOOGLE_TAG_MANAGER_ENV_ID}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
          // })(window,document,'script','dataLayer','${process.env.GOOGLE_TAG_MANAGER_KEY}');`,
          __html: `(function(w,d,s,l,i){
            w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;
            j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.GOOGLE_TAG_MANAGER_KEY}');`,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          // __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.GOOGLE_TAG_MANAGER_KEY}&gtm_auth=${process.env.GOOGLE_TAG_MANAGER_AUTH}&gtm_preview=${process.env.GOOGLE_TAG_MANAGER_ENV_ID}&gtm_cookies_win=x"
          // height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.GOOGLE_TAG_MANAGER_KEY}"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />
    </>
  );
}

export default MyApp;

export const FooterLinks = [
  {
    id: 1,
    path: "/shop",
    text: "Shop",
  },
  {
    id: 2,
    path: "/our-difference",
    text: "Our Difference",
  },
  {
    id: 3,
    path: "/our-story",
    text: "Our Story",
  },
  {
    id: 4,
    path: "/our-team",
    text: "Our Team",
  },
  {
    id: 5,
    path: "/blog",
    text: "Blog",
  },
];
